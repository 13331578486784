import React from "react";
import { Link } from "react-router-dom";

const PostCard = () => {
  return (
    <>
      <div className="postCard">
        <div className="container">
          <div className="postCardInfo">
            <div className="postText">
              <h5>
                Post your Property for <span className="freeText">free</span>
              </h5>
              <p>List it on REALESTATE and get genuine leads</p>
            </div>
            <div className="postProp">
              <Link to={"/"}>
                Post Property <span className="free">free</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCard;
