import React from "react";

const Apartments = () => {
  return (
    <>
      <section className="apartments sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h2>Explore Apartment Types</h2>
                <p>Get some Inspirations from 1800+ skills</p>
              </div>
            </div>

            <div className="col-lg-2">
              <div className="apartmentCard">
                <div className="icon">
                  <i className="ri-home-4-line"></i>
                </div>
                <div className="title">
                  <h5>Houses</h5>
                  <p>22 Properties</p>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="apartmentCard">
                <div className="icon">
                  <i className="ri-building-line"></i>
                </div>
                <div className="title">
                  <h5>Apartments</h5>
                  <p>22 Properties</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="apartmentCard">
                <div className="icon">
                  <i className="ri-building-2-line"></i>
                </div>
                <div className="title">
                  <h5>Office</h5>
                  <p>22 Properties</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="apartmentCard">
                <div className="icon">
                <i className="ri-ancient-pavilion-line"></i>
                </div>
                <div className="title">
                  <h5>Villa</h5>
                  <p>22 Properties</p>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="apartmentCard">
                <div className="icon">
                <i className="ri-home-8-line"></i>
                </div>
                <div className="title">
                  <h5>Townhome</h5>
                  <p>22 Properties</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Apartments;
