import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="footer-widget mb-4 mb-lg-5">
                <Link className="brand mb-5" to="/">
                  <i className="ri-home-8-line"></i>
                </Link>
                <div className="row mb-4 mb-lg-5">
                  <div className="col-auto">
                    <div className="contact-info">
                      <p className="info-title">Total Free Customer Care</p>
                      <h6 className="info-phone">
                        <a href="tel:+012305094502">+91 987 654 3210</a>
                      </h6>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="contact-info">
                      <p className="info-title">Need Live Support?</p>
                      <h6 className="info-mail">
                        <a href="mailto:example@gmail.com">example@gmail.com</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="app-widget">
                  <h5 className="title text-white mb10">Apps</h5>
                  <div className="row mb-4 mb-lg-5">
                    <div className="col-auto">
                      <Link to="/" target="_blank">
                        <div className="app-info ">
                          <div className="icon">
                            <i className="ri-apple-fill"></i>
                          </div>
                          <div className="text">
                            <p>Download on the</p>
                            <h6>Apple Store</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-auto">
                      <Link to="/" target="_blank">
                        <div className="app-info ">
                          <div className="icon">
                            <i className="ri-google-play-fill"></i>
                          </div>
                          <div className="text">
                            <p className="app-text">Get it on</p>
                            <h6>Google Play</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="social-widget">
                  <h6 className="text-white mb20">Follow us on social media</h6>
                  <div className="social-style1">
                    <Link to="/">
                      <i className="ri-facebook-fill"></i>
                    </Link>
                    <Link to="/">
                      <i className="ri-twitter-fill"></i>
                    </Link>
                    <Link to="/">
                      <i className="ri-instagram-fill"></i>
                    </Link>
                    <Link to="/">
                      <i className="ri-linkedin-fill"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="footer-widget mb-4 mb-lg-5">
                <div className="mailchimp-widget mb-4 mb-lg-5">
                  <h6 className="title text-white">Keep Yourself Up to Date</h6>
                  <div className="mailchimp-style1">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                    />
                    <button type="submit">Subscribe</button>
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-auto">
                    <div className="link-style1 mb-3">
                      <h6 className="text-white mb25">Popular Search</h6>
                      <ul className="ps-0">
                        <li>
                          <a href="#">Apartment for Rent</a>
                        </li>
                        <li>
                          <a href="#">Apartment Low to Hide</a>
                        </li>
                        <li>
                          <a href="#">Offices for Buy</a>
                        </li>
                        <li>
                          <a href="#">Offices for Rent</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="link-style1 mb-3">
                      <h6 className="text-white mb25">Quick Links</h6>
                      <ul className="ps-0">
                        <li>
                          <a href="#">Terms of Use</a>
                        </li>
                        <li>
                          <a href="#">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="#">Pricing Plans</a>
                        </li>
                        <li>
                          <a href="#">Our Services</a>
                        </li>
                        <li>
                          <a href="#">Contact Support</a>
                        </li>
                        <li>
                          <a href="#">Careers</a>
                        </li>
                        <li>
                          <a href="#">FAQs</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="link-style1 mb-3">
                      <h6 className="text-white mb25">Discover</h6>
                      <ul className="ps-0">
                        <li>
                          <a href="#">Miami</a>
                        </li>
                        <li>
                          <a href="#">Los Angeles</a>
                        </li>
                        <li>
                          <a href="#">Chicago</a>
                        </li>
                        <li>
                          <a href="#">New York</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="copyRight">
            <div className="copyContent">
              <p>
                © Real Estate 2023
                <Link to="/" target="_blank" className="ms-2 me-2">
                  Abaris Softech
                </Link>
                - All rights reserved
              </p>
            </div>
            <div className="copyContent">
              <div className="footer-menu">
                <a className="text-gray" href="#">
                  Privacy
                </a>
               
                <a className="text-gray" href="#">
                  Terms
                </a>
                
                <a className="text-gray" href="#">
                  Sitemap
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
