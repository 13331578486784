import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
import LoginModal from "./login-modal/LoginModal";

const Header = () => {
  const [scroll, setScroll] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


 useEffect(() => {
   window.addEventListener("scroll", () => {
     setScroll(window.scrollY > 20);
   });
 }, []);
  return (
    <>
      <header className={scroll ? "header active" : "header"}>
        <nav className="navbar navbar-expand-lg navHeader">
          <div className="container">
            <Link className="brand" to="/">
              <i className="ri-home-8-line"></i>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav menuList">
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/">
                    Buy
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Rent
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Sell
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home Loans
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Property Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Help Center
                  </Link>
                </li>
              </ul>
             
            </div>
            <div className="exploreMenu">
                <div className="login">
                <i className="ri-user-3-line"></i>
                  <span variant="primary" onClick={handleShow}>Login / Register</span>
                </div>
                <div className="addProp">
                  <Link to={"/"}>Add Property <BsArrowUpRight /></Link>
                </div>
                <div className="bar">
                <i className="ri-menu-3-line"></i>
                </div>
              </div>
          </div>
        </nav>
      </header>

      <LoginModal data={{show, handleClose}} />
    </>
  );
};

export default Header;
