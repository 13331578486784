import React from "react";
import budgetHomes from "../../../assets/images/property/budget-homes.webp";
import newProjects from "../../../assets/images/property/new-projects.webp";
import ownerProperty from "../../../assets/images/property/owner-property.webp";
import { Link } from "react-router-dom";

const Properties = () => {
  return (
    <>
      <section className="properties sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h2>We've got properties for everyone</h2>
                <span className="titleLine"></span>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="propertyCard">
                <div className="image">
                  <img src={budgetHomes} alt="Home" className="img-fluid" />

                  <div className="title">
                    <h5>13567</h5>
                    <p>Owner Properties</p>
                    <Link to="/">
                      explore <i className="ri-arrow-right-s-line"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="propertyCard">
                <div className="image">
                  <img src={newProjects} alt="Home" className="img-fluid" />

                  <div className="title">
                    <h5>567</h5>
                    <p>Project</p>
                    <Link to="/">
                      explore <i className="ri-arrow-right-s-line"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="propertyCard">
                <div className="image">
                  <img src={ownerProperty} alt="Home" className="img-fluid" />

                  <div className="title">
                    <h5>1357</h5>
                    <p>Budget Homes</p>
                    <Link to="/">
                      explore <i className="ri-arrow-right-s-line"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Properties;
