import React from "react";
import hero from "../../assets/images/banner/hero.jpg";
import Banner from "./banner/Banner";
import AdvanceSearch from "./advance-search/AdvanceSearch";
import Apartments from "./apartments/Apartments";
import Properties from "./properties/Properties";
import PropertyService from "./property-service/PropertyService";
import Advice from "./advice/Advice";
import PostCard from "./post-card/PostCard";
import About from "./about/About";
import TopCities from "./top-cities/TopCities";
const Home = () => {
  return (
    <>
      <section className="banner" style={{ backgroundImage: `url("${hero}")` }}>
        <div className="container">
          <Banner />
          <AdvanceSearch />
        </div>
      </section>
      <Apartments />
      <Properties />
      <PropertyService />
      <Advice />
      <About />
      <PostCard/>
      <TopCities />
    </>
  );
};

export default Home;
