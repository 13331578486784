import React from "react";
import { Nav } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const LoginModal = ({ data }) => {
  const { show, handleClose } = data;
  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName="loginMod">
        <Modal.Header closeButton>
          <Modal.Title>Welcome to RealEstate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalTab">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="signIn-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#signIn-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  Sign In
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="account-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#account-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="account-tab-pane"
                  aria-selected="false"
                >
                  New Account
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="signIn-tab-pane"
                role="tabpanel"
                aria-labelledby="signIn-tab"
                tabIndex={0}
              >
                <div className="loginTabContent">
                  <form className="mt-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter Password"
                      />
                    </div>
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck1"
                      >
                        Remember Me
                      </label>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Sign In
                    </button>
                  </form>
                  <hr />
                  <p className="signInText">Not signed up? <a href="/register">Create an account.</a></p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="account-tab-pane"
                role="tabpanel"
                aria-labelledby="account-tab"
                tabIndex={0}
              >
                 <div className="loginTabContent">
                  <form className="mt-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter Password"
                      />
                    </div>
                    
                    <button type="submit" className="btn btn-primary">
                      Create Account
                    </button>
                  </form>
                  <hr />
                  <p className="signInText">Already Have an Account? <a href="/login">Login.</a></p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;
