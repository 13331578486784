import React from "react";
import budgetHomes from "../../../assets/images/property/budget-homes.webp";
import newProjects from "../../../assets/images/property/ready-to-move-in.webp";
import ownerProperty from "../../../assets/images/property/owner-property.webp";
import { Link } from "react-router-dom";

const PropertyService = () => {
  return (
    <>
      <section className="properties sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h2>Property Service</h2>
                <span className="titleLine"></span>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="serveCard">
                <div className="image">
                  <img src={budgetHomes} alt="Home" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Packers and Movers</h5>
                  <p>
                    Home shifting and Vehicle shifting at Guaranteed lowest
                    prices
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="serveCard">
                <div className="image">
                  <img src={newProjects} alt="Home" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Best Home Loan Deals</h5>
                  <p>
                    Compare & choose from 34+ banks to get the best home loan
                    deal
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="serveCard">
                <div className="image">
                  <img src={ownerProperty} alt="Home" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Home Decor</h5>
                  <p>Transform your space with our trusted Decor partners</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="serveCard">
                <div className="image">
                  <img src={budgetHomes} alt="Home" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Packers and Movers</h5>
                  <p>
                    Home shifting and Vehicle shifting at Guaranteed lowest
                    prices
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PropertyService;
