import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import BackTop from "./components/back-top/BackTop";


function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Footer />

      <BackTop />
    </>
  );
}

export default App;
