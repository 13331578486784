import React from "react";
import { Link } from "react-router-dom";

const Advice = () => {
  return (
    <>
      <section className="adviceTool bgGray sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h2>Advice & Tools</h2>
                <span className="titleLine"></span>

              </div>
            </div>

            <div className="col-lg-4">
              <div className="adviceToolCard">
                <div className="icon">
                  <i className="ri-home-4-line"></i>
                </div>
                <div className="title">
                  <h5>Property Valuation</h5>
                  <p>Know the right value for your Property before you Buy or Sell.</p>
                  <Link to={"/"}>Read More <i className="ri-arrow-right-line"></i></Link>
                </div>
                <span className="verifiedText">Certified</span>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="adviceToolCard">
                <div className="icon">
                <i className="ri-file-list-3-line"></i>
                </div>
                <div className="title">
                  <h5>Legal Title Check</h5>
                  <p>Get property checked for clean title and no legal claim/bank attachment</p>
                  <Link to={"/"}>Read More <i className="ri-arrow-right-line"></i></Link>
                </div>
                <span className="verifiedText">get free</span>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="adviceToolCard">
                <div className="icon">
                <i className="ri-ai-generate"></i>
                </div>
                <div className="title">
                  <h5>Rates & Trends</h5>
                  <p>Know all about Property Rates & Trends in your city</p>
                  <Link to={"/"}>Read More <i className="ri-arrow-right-line"></i></Link>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default Advice;
