import React from "react";

const AdvanceSearch = () => {
  return (
    <>
      <div className="advanceSearch">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
            >
              Buy
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
            >
              Rent
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact-tab-pane"
              type="button"
            >
              Sold
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="home-tab-pane">
            <div className="advance-content-style1">
              <div className="row align-items-center">
                <div className="col-md-8 col-lg-9">
                  <div className="advance-search-field position-relative text-start">
                    <form className="form-search position-relative">
                      <div className="box-search">
                        <i className="ri-home-2-line"></i>
                        <input
                          className="form-control bgc-f7 bdrs12"
                          type="text"
                          placeholder="Enter an address, neighborhood, city, or ZIP code for Buy"
                          name="search"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="advance-search-field1">
                    <button
                      className="advance-search-btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#advanceSeachModal"
                    >
                      <i className="ri-equalizer-fill"></i> Advanced
                    </button>
                    <button className="btn btn-primary" type="button">
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="profile-tab-pane">
          <div className="advance-content-style1">
              <div className="row align-items-center">
                <div className="col-md-8 col-lg-9">
                  <div className="advance-search-field position-relative text-start">
                    <form className="form-search position-relative">
                      <div className="box-search">
                        <i className="ri-home-2-line"></i>
                        <input
                          className="form-control bgc-f7 bdrs12"
                          type="text"
                          placeholder="Enter an address, neighborhood, city, or ZIP code for Sell"
                          name="search"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="advance-search-field1">
                    <button
                      className="advance-search-btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#advanceSeachModal"
                    >
                      <i className="ri-equalizer-fill"></i> Advanced
                    </button>
                    <button className="btn btn-primary" type="button">
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="contact-tab-pane">
          <div className="advance-content-style1">
              <div className="row align-items-center">
                <div className="col-md-8 col-lg-9">
                  <div className="advance-search-field position-relative text-start">
                    <form className="form-search position-relative">
                      <div className="box-search">
                        <i className="ri-home-2-line"></i>
                        <input
                          className="form-control bgc-f7 bdrs12"
                          type="text"
                          placeholder="Enter an address, neighborhood, city, or ZIP code for Sold"
                          name="search"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="advance-search-field1">
                    <button
                      className="advance-search-btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#advanceSeachModal"
                    >
                      <i className="ri-equalizer-fill"></i> Advanced
                    </button>
                    <button className="btn btn-primary" type="button">
                      <i className="ri-search-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvanceSearch;
