import img1 from "../../../assets/images/city/236961707D-1594715125517.webp";
import img2 from "../../../assets/images/city/236960749D-1594714810078.webp";
import img3 from "../../../assets/images/city/336143474D-1640587363487.webp";
import img4 from "../../../assets/images/city/236932009D-1594709336922.webp";
import img5 from "../../../assets/images/city/236975527D-1594718126587.webp";
import img6 from "../../../assets/images/city/236967665D-1594716318858.webp";
import img7 from "../../../assets/images/city/236973031D-1594717541096.webp";
import React from "react";

const TopCities = () => {
  return (
    <>
      <section className="topCities bgGray sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sectionTitle">
                <h6>TOP CITIES</h6>
                <h2>Explore Real Estate in Popular Indian Cities</h2>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="citiesCard">
                <div className="img">
                  <img src={img1} alt="Delhi" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Delhi / NCR</h5>
                  <p>155,000+ Properties</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="citiesCard">
                <div className="img">
                  <img src={img2} alt="Delhi" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Bangalore</h5>
                  <p>41,000+ Properties</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="citiesCard">
                <div className="img">
                  <img src={img3} alt="Delhi" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Pune</h5>
                  <p>155,000+ Properties</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="citiesCard">
                <div className="img">
                  <img src={img4} alt="Delhi" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Chennai</h5>
                  <p>32,000+ Properties</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="citiesCard">
                <div className="img">
                  <img src={img5} alt="Delhi" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Mumbai</h5>
                  <p>155,000+ Properties</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="citiesCard">
                <div className="img">
                  <img src={img6} alt="Delhi" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Hyderabad</h5>
                  <p>28,000+ Properties</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="citiesCard">
                <div className="img">
                  <img src={img7} alt="Delhi" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Kolkata</h5>
                  <p>155,000+ Properties</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="citiesCard">
                <div className="img">
                  <img src={img1} alt="Delhi" className="img-fluid" />
                </div>
                <div className="title">
                  <h5>Delhi / NCR</h5>
                  <p>155,000+ Properties</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopCities;
