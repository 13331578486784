import React from "react";
import about1 from "../../../assets/images/about/about-1.webp";
import about2 from "../../../assets/images/about/about-2.webp";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <section className="betterPlace sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="aboutInfo">
                <h6>ALL PROPERTY NEEDS - ONE PORTAL</h6>
                <h2>Find Better Places to Live, Work and Wonder...</h2>
                <p>
                  As the complexity of buildings to increase, the field of
                  architecture
                </p>
                <ul>
                  <li> <i className="ri-check-line"></i> Find excellent deals</li>
                  <li><i className="ri-check-line"></i> Friendly host & Fast support</li>
                  <li> <i className="ri-check-line"></i> List your own property</li>
                </ul>
                <Link to="/">learn more</Link>
              </div>
            </div>
            <div className="col-lg-8">
                <div className="aboutImg">
                    <img src={about1} alt="About" className="img-fluid" />
                    <img src={about2} alt="About" className="img-fluid" />
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
