import React from 'react'

const Banner = () => {
  return (
    <>
        <div className="bannerContent">
            <h6>THE BEST WAY TO</h6>
            <h1>Find Your Dream Home</h1>
            <p>We’ve more than 745,000 apartments, place & plot.</p>
        </div>
    </>
  )
}

export default Banner